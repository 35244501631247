import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class EcommerceService {
  constructor(
    private http: HttpClient
  ) { }

  async getData(data: any){
    // if(data.sectionType === 'featuredProduct') {
    //   const res: any = await lastValueFrom(this.getFeaturedProduct(data.collectionId, data.subIndustryId, data.businessId));
    //   return res?.data;
    // }
    if(data.sectionType === 'productList') {
      const res: any = await lastValueFrom(this.getProductList(data.businessId, data.categoryId, data.subIndustryId));
      return res?.data;
    }
    if(data.sectionType === 'featuredCategory') {
      const res: any = await lastValueFrom(this.getCategoryData(data.businessId, data.subIndustryId));
      return res?.data;
    }
    if(data.sectionType === 'productDescription') {
      const res: any = await lastValueFrom(this.getProductDesc(data.productId, data.subIndustryId));
      return res?.data;
    }
    // if(data.sectionType === 'cart' || data.sectionType === 'checkout') {
    //   const res: any = await lastValueFrom(this.getUserCart(data.userId));
    //   return res?.data;
    // }
    return null;
  }

  getCategoryData(businessId: any, subIndustryId: any){
    return this.http.get(
      environment.baseUrl + `ecommerce/inventory/featured?businessId=${businessId || ''}&subId=${subIndustryId}`
    )
  }

  getFeaturedProduct(collectionId: any, subIndustryId: any, businessId: any){
    return this.http.get(
      environment.baseUrl + `ecommerce/product/collection/id?businessId=${businessId || ''}&collectionId=${collectionId || ''}&subIndustryId=${subIndustryId}`
    )
  }

  getProductList(businessId: any, categoryId: any, subIndustryId: any){
    return this.http.get(
      environment.baseUrl + `ecommerce/product/category/id?businessId=${businessId || ''}&subIndustryId=${subIndustryId}&categoryId=${categoryId}`
    )
  }

  getProductDesc(productId: any, subIndustryId: any){
    return this.http.get(
      environment.baseUrl + `ecommerce/product/product/id?itemId=${productId}&subId=${subIndustryId}`
    )
  }

  getUserCart(userId: any){
    return this.http.get(
      environment.baseUrl + ``
    )
  }

  getAllIndustries(){
    return this.http.get(
      environment.baseUrl + 'admin/ecommerce/subIndustries'
    )
  }

  getDummyProductList(subIndustryId: any){
    return this.http.get(
      environment.baseUrl+`admin/ecommerce/get/items?subIndustryId=${subIndustryId}`
    )
  }

  getDummyCategory(subIndustryId: any){
    return this.http.get(
      environment.baseUrl+`admin/ecommerce/categories?subIndustryId=${subIndustryId}`
    )
  }
  getDummyProduct(subIndustryId: any){
    return this.http.get(
      environment.baseUrl+`admin/ecommerce/random/item?subIndustryId=${subIndustryId}`
    )
  }

  getOrderList(payload: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/order/user/order`, payload
    )
  }

  updateOrderStatus(orderId: any, status: any, cancelReason: string) {
    return this.http.put(
      environment.baseUrl + `ecommerce/order/update/status?orderId=${orderId}&orderStatus=${status}&orderCancelText=${cancelReason}`, null
    )
  }

  getListCustomers(businessId: any,startDate :any,endDate :any, searchText: any, pageNo: any, pageSize: any){
    return this.http.get(
      environment.baseUrl+`ecommerce/user/filter/customer?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}&searchText=${searchText}&pageNo=${pageNo}&pageSize=${pageSize}`
    )
  }

  getListCustomersDiscount(businessId: any,pageNo: any,pageSize: any,searchText : any)
  {
    return this.http.get(
      environment.baseUrl+`ecommerce/user/filter/customer?businessId=${businessId}&searchText=${searchText}&pageNo=${pageNo}&pageSize=${pageSize}`
    )
  }

  getOrderByCustomerId(customerId: any,status: any) {
    if(status){
      return this.http.put(
        environment.baseUrl + `ecommerce/user/filter/orders?userId=${customerId}&status=${status}`, ''
      )
    } else {
      return this.http.put(
        environment.baseUrl + `ecommerce/user/filter/orders?userId=${customerId}`, ''
      )
    }

  }

  getUserId(customerId: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/user/get/user?userId=${customerId}`, ''
    )
  }

  updateProductFromCategoryOrCollection(payload: any){
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/item/category/collection`, payload
    )
  }

  updateCategoryFromCollection(payload: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/item/update/category`, payload
    )
  }

  updateTrackingUrl(url: string, userId: string) {
    return this.http.patch(
      environment.baseUrl + `ecommerce/order/order/track?trackingLink=${url}&userOrderId=${userId}`, ''
    )
  }

  saveCustomer(data:any){
    return this.http.post(
      environment.baseUrl+`ecommerce/user/business-user/create`,data
    )
  }
  // updateCustomer(customerId:any,data:any){
  //   return this.http.post(
  //     environment.baseUrl + `ecommerce/user/business-user/create/update?customerId=${customerId}`,data
  //   )
  // }
  // getCustomerDetails(customerId: string) {
  //   return this.http.get<any>(
  //     `${environment.baseUrl}ecommerce/user/business-user/${customerId}`
  //   );
  // }

  getAdundantCartData(businessId: any, pageNo: any, pageSize: any){
    return this.http.get(
      environment.baseUrl+`ecommerce/order/cart/abandoned/${businessId}?pageNo=${pageNo}&pageSize=${pageSize}`
    )
  }

  searchAdundantCart(businessId: any, keyword: any, pageNo: any, pageSize: any){
    return this.http.get(
      environment.baseUrl+`ecommerce/order/search/abandoned/${businessId}/cart?keyword=${keyword}&pageNo=${pageNo}&size=${pageSize}`
    )
  }

  abundantCartFilter(payload: any){
    return this.http.put(
      environment.baseUrl + `ecommerce/order/cart/abandoned/filter`, payload
    )
  }

  // discount api

  createDiscount(data:any){
    return this.http.post(
      environment.baseBusinessUrl+`discount-coupon`,data
    )
  }
  getTypeList()
  {
    return this.http.get(
      environment.baseBusinessUrl+`discount-type-master/list`
    )
  }
  getDiscountList(payload : any)
  {
     return this.http.put(
      environment.baseBusinessUrl+`discount-coupon`,payload
   )
  }
  deleteDiscountCoupon(id : any)
  {
     return this.http.delete(
      environment.baseBusinessUrl+`discount-coupon/soft-delete?couponId=${id}`
     )
  }


  fetchReviewsByItemId(payload: any){
    return this.http.put(
      environment.baseUrl+ `ecommerce/user/filter/reviews`, payload
    )
  }
  getReviewCountByProductId(productId:any,){
    return this.http.get(
      environment.baseUrl+  `ecommerce/user/rating/review/count?productId=${productId}`,
    )
  }
  getCommonPhrases(itemId:any,){
    return this.http.get(
      environment.baseUrl+  `ecommerce/user/all?itemId=${itemId}`,
    )
  }

  editreview(reviewId : any,reviewStatus : any)
  {
    return this.http.put(
      environment.baseUrl + `ecommerce/user/review-rating/${reviewId}/update-status?reviewStatus=${reviewStatus}`,''
    )
  }

  openreviewratingproducts(businessId: any , payLoad: any ) {
    return this.http.put(
      environment.baseUrl+ `ecommerce/user/review-rating/${businessId}/business-product-review` , payLoad
    )
  }

  deletereview(reviewId : any)
  {
    return this.http.delete(
      environment.baseUrl+ `ecommerce/user/review-rating/${reviewId}/soft-delete`
    )

  }
  getbusinessreviewrating(businessId: any , startDate: any, endDate:any)
  {
    return this.http.get(
      environment.baseUrl + `ecommerce/user/rating-reviews/graph/stats?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`
    )
  }
  globalbusinessreview(businessId: string)
  {
    return this.http.get(
      environment.baseUrl + `ecommerce/user/rating/review/count?businessId=${businessId}`

    )
  }
  globalproductreview(itemId:string)
  {
    return this.http.get(
      environment.baseUrl + `ecommerce/user/rating/review/count?productId=${itemId}`
    )
  }

  listreviewdproducts(businessId:any){
    return this.http.get(
      environment.baseUrl+ `ecommerce/user/rating-review/${businessId}/status-wise/count`
    )
  }

  getEcomDashboardData(payload: any){
    return this.http.put(
      environment.baseUrl + `reports/report/ecom/dashboard`, payload
    )
  }

}
