import { ComponentFactoryResolver, ComponentRef, HostListener, Injectable, ViewContainerRef } from '@angular/core';
import { AddNewSectionComponent, AuthenticateUserComponent, AuthenticationRequiredComponent, BannerCarouselComponent, BannerGridSectionComponent, BannerSectionComponent, BlogListComponent, CarouselBannerComponent, CartComponent, CategoryProductComponent, CheckoutComponent, ChooseUsSectionComponent, Collection, ContactUsComponent, CustomerReviewComponent, FaqSectionComponent, FeaturedCategoryComponent, FeaturedCollectionComponent, FeaturedProductsComponent, FeaturesSectionComponent, FooterSectionComponent, HeaderTextComponent, ImageCarouselSectionComponent, ImageGridSectionComponent, ImageSectionComponent, LocationSectionComponent, LogoShowcaseComponent, NavbarSectionComponent, PricingSectionComponent, ProcessModernComponent, ProcessSectionComponent, ProductCategoryListComponent, ProductDescComponent, ProductListComponent, RecentBlogPostSectionComponent, ServiceSectionComponent, SkeletonLoaderSectionComponent, TeamMemberSectionComponent, TestimonialFullwidthComponent, TestimonialSectionComponent, TextImageSectionComponent, TextSectionComponent, UserProfileComponent, VerifyComponent, VideoSectionComponent, ViewBlogComponent, WhislistComponent } from 'simpo-component-library';
import { BlogServiceService } from '../master-config-components/micro-apps/website/edit-website/website-generationcomponent/blogs/blog-service.service';
import { StorageKeys } from '../shared-module-files/simpo.constant';

@Injectable({
  providedIn: 'root'
})
export class WebsiteGenerationService {

  componentsReferences = Array<ComponentRef<any>>()

  constructor(
    private comFactoryResolver: ComponentFactoryResolver,
    private blogService: BlogServiceService
  ) {
    this.getScreenSize();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  listComponents: any = [
    {
      sectionType: 'banner',
      component: BannerSectionComponent
    },
    {
      sectionType: 'text',
      component: TextSectionComponent
    },
    {
      sectionType: 'image',
      component: ImageSectionComponent
    },
    {
      sectionType: 'text_image',
      component: TextImageSectionComponent
    },
    {
      sectionType: 'service',
      component: ServiceSectionComponent
    },
    {
      sectionType: 'testimonial',
      component: TestimonialSectionComponent
    },
    {
      sectionType: 'imagegrid',
      component: ImageGridSectionComponent
    },
    {
      sectionType: 'imageCarousel',
      component: ImageCarouselSectionComponent
    },
    {
      sectionType: 'video',
      component: VideoSectionComponent
    },
    {
      sectionType: 'teammember',
      component: TeamMemberSectionComponent
    },
    {
      sectionType: "header",
      component:NavbarSectionComponent
    },
    {
      sectionType: "footer",
      component:FooterSectionComponent
    },
    {
      sectionType: "addNewSection",
      component:AddNewSectionComponent
    },
    {
      sectionType: "contact us",
      component:ContactUsComponent
    },
    {
      sectionType: "section-loader",
      component:SkeletonLoaderSectionComponent
    },
    {
      sectionType: "faq",
      component: FaqSectionComponent
    },
    {
      sectionType: "bannerGrid",
      component: BannerGridSectionComponent
    },
    {
      sectionType: "chooseUs",
      component: ChooseUsSectionComponent
    },
    {
      sectionType: "pricing",
      component: PricingSectionComponent
    },
    {
      sectionType: "headerText",
      component: HeaderTextComponent
    },
    {
      sectionType: 'location',
      component: LocationSectionComponent
    },
    {
      sectionType: 'blogPost',
      component: RecentBlogPostSectionComponent
    },
    {
      sectionType: 'blogList',
      component: BlogListComponent
    },
    {
      sectionType: 'viewBlog',
      component: ViewBlogComponent
    },
    {
      sectionType: 'process',
      component: ProcessSectionComponent
    },
    {
      sectionType: 'processModern',
      component: ProcessModernComponent
    },
    {
      sectionType: 'feature',
      component: FeaturesSectionComponent
    },
    {
      sectionType: 'testimonialFullWidth',
      component: TestimonialFullwidthComponent
    },
    {
      sectionType: 'logoShowcase',
      component: LogoShowcaseComponent
    },
    {
      sectionType: 'featuredProduct',
      component: FeaturedProductsComponent
    },
    {
      sectionType: 'featuredCategory',
      component: FeaturedCategoryComponent
    },
    {
      sectionType: 'productList',
      component: ProductListComponent
    },
    {
      sectionType: 'productDescription',
      component: ProductDescComponent
    },
    {
      sectionType: 'cart',
      component: CartComponent
    },
    {
      sectionType: 'checkout',
      component: CheckoutComponent
    },
    {
      sectionType: 'wishlist',
      component: WhislistComponent
    },
    {
      sectionType: 'profile',
      component: UserProfileComponent
    },
    {
      sectionType: 'authenticate',
      component: AuthenticateUserComponent
    },
    {
      sectionType: 'verify-payment',
      component: VerifyComponent
    },
    {
      sectionType: 'carouselBanner',
      component: CarouselBannerComponent
    },
    {
      sectionType: 'bannerCarousel',
      component: BannerCarouselComponent
    },
    {
      sectionType: 'categoryProduct',
      component: CategoryProductComponent
    },
    {
      sectionType: 'productCategoryList',
      component: ProductCategoryListComponent
    },
    {
      sectionType: 'customerReview',
      component: CustomerReviewComponent
    },
    {
      sectionType: 'featuredCollection',
      component: FeaturedCollectionComponent
    },
    {
      sectionType: 'authenticationrequired',
      component: AuthenticationRequiredComponent
    }
  ];


  webStyleSheet = {
    'padding-left': '5%',
    'padding-right': '5%'
  }

  mobileStyleSheet = {
    'padding-left': '3%',
    'padding-right': '3%'
  }

  createDynamicComponents(displaySection : ViewContainerRef | any, sectionList: any[], edit, responseData?: any) {

    this.componentsReferences = [];
    displaySection?.clear()
    sectionList.forEach((section, index) => {
      for(let component of this.listComponents) {
        if(component.sectionType === section.sectionType){
          let responseData;
          if(component.sectionType === 'blogPost' || component.sectionType === 'blogList') {
            this.updateSection(displaySection, index, section, edit)
            continue;
          }
          if(component.sectionType === 'viewBlog') {
            this.addComponent(displaySection, section,edit, index)
            continue;
          }
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(component.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.data = section;
          if(section.sectionType === 'header') {
            k.instance.nextComponent = sectionList[1];
          }
          k.instance.index = index;
          k.instance.responseData = section.responseData;
          k.instance.edit = edit;

          if (section.sectionType.includes('banner')) {
            if (index != 1)
              delete k.instance.data.styles.merge;
            else
              k.instance.data.styles.merge = false;
          }

          this.componentsReferences.push(k);
        }
      }
    })

    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

  addComponent(displaySection: ViewContainerRef | any, newComponment: any, edit:boolean, index:number, sectionType?: any) {
    let componentFact = this.listComponents.forEach(async (listCompos, i) => {
      if (listCompos.sectionType === newComponment.sectionType) {
        let responseData;
        if(newComponment.sectionType === 'viewBlog') {
          responseData = await this.blogService.getBlogByBlogId(newComponment.blogId)
        }

        const componentFactory = this.comFactoryResolver.resolveComponentFactory(listCompos.component);
        const k = displaySection.createComponent(componentFactory, index);
        k.instance.data = newComponment;

        if(responseData) {
          k.instance.responseData = responseData.data;
        }

        if(sectionType) k.instance.sectionType = sectionType;
        if (sectionType.includes('banner')) {
          if (index != 1)
            delete k.instance.data.styles.merge;
          else
            k.instance.data.styles.merge = false;
        }
        k.instance.edit = edit;

        k.instance.canEditAll = true;
        k.instance.index = index;

        this.componentsReferences.splice(index, 0, k);

        for(let i = index + 10 + 1;i<displaySection._lContainer.length;i++){
          if(displaySection._lContainer[i][8].index){
            displaySection._lContainer[i][8].index = displaySection._lContainer[i][8].index + 1;
          }
        }

      }
    });
    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

  updateSection(displaySection,index:number,newData:any,edit: boolean, sectionType?: any){
    const componentToRemove = displaySection.get(index);
    if (componentToRemove) {
      componentToRemove.destroy();
    }

    this.componentsReferences.splice(index,1);
    this.listComponents.forEach(async (listCompos, i) => {
      if (listCompos.sectionType === newData.sectionType) {
        let responseData;
        if(listCompos.sectionType === 'blogPost' || listCompos.sectionType === 'blogList') {
          let businessId = JSON.parse(String(localStorage.getItem('bDetails')))?.id;
          if(businessId) {
            responseData = await this.blogService.getBlogSynchronousByBuisnessId(businessId)
          } else {
            let subId = JSON.parse(String(localStorage.getItem(StorageKeys.INDUSTRY_DETAILS))).subIndustryId;
            responseData = await this.blogService.getBlogSynchronousByBuisnessId(null, subId);
          }

        }
        const componentFactory = this.comFactoryResolver.resolveComponentFactory(listCompos.component);
        const k = displaySection.createComponent(componentFactory, index);
        k.instance.data = newData;
        if(index == 1) {
          displaySection._lContainer[10][8].nextComponent = newData
        }
        k.instance.edit = edit;
        if(responseData) {
          k.instance.responseData = responseData.data;
        }
        if(sectionType) k.instance.sectionType = sectionType;

        if (listCompos.sectionType?.includes('banner')) {
          if (index != 1)
            delete k.instance.data.styles.merge;
          else
            k.instance.data.styles.merge = false;
        }
        k.instance.index = index;
        this.componentsReferences.splice(index, 0, k);

      }
    });
    return this.componentsReferences?.[0]?.instance?._eventService || null;
  }

  replaceComponent(displaySection, oldItem, newItem, edit, all) {
    let a = this.listComponents.forEach((listComps, i) => {
      // if(listComps.name === oldItem.webComponentName){
      //   let componentClass = listComps.component
      //   const component = this.componentsReferences.find((component) => component.instance instanceof componentClass);
      //   if(!component) return ;
      //   const index = this.componentsReferences.indexOf(component);
      // const componentFactory = this.comFactoryResolver.resolveComponentFactory(listComps.component);
      // const k = displaySection.createComponent(componentFactory, index);

      //   if(index !== -1){
      //     displaySection.splice(index,1,k);
      //     this.componentsReferences.splice(index,k)
      //   }
      // }
      if (listComps.name === oldItem.webComponentName) {
        let oldCompClass = listComps.component;
        const component = this.componentsReferences.find((component) => component.instance instanceof oldCompClass);
        if (!component) return;
        const oldCompIndex = this.componentsReferences.indexOf(component);
        if (listComps.name === newItem.webComponentName) {
          let newCompClass = listComps.component;
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(newCompClass);
          const k = displaySection.createComponent(componentFactory, oldCompIndex);
          this.componentsReferences.splice(oldCompIndex, 1, k)
        }
      }
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  samePageRedirection(selectedPage, id) {
    let a = document.querySelectorAll('*[id]');
    let b;
    a.forEach((singleNode) => {
      if (singleNode.id === selectedPage.components[id]) {
        b = document.getElementById(singleNode.id);
        if (!b) return;
        b.scrollIntoView({ behavior: 'smooth' });
      }
    });
    return b;
  }
  externelUrls(newPage) {
    window.open(newPage, '_blank')
  }
}
